<template>
  <div>
    
  </div>
</template>

<script>
export default {

}
</script>
<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    .para-total {
        background-color: rgb(68, 171, 231) !important;
        color: black !important;
    }
    th, td {
        font-size: 12pt;
        color: black;
        font-weight: 500;
    }
    .table-only-print tbody tr td {
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .table-only-print {
        display: none;
    }
    @media print {
        #content-wrapper {
            background-color: white !important;
        }
        .no-print, #nav {
            display: none;
        }
        .table-only-print {
            display: table !important;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
            
        }
        .expired-passport-date.bg-danger {
            background-color: #e74a3b !important;
        }
        .expired-passport-date.bg-warning {
            background-color: #f6c23e !important;
        }
        .expired-passport-date.bg-success {
            background-color: #1cc88a !important;
        }
        .modal-content {
            width: 200%;

        }
        .modal-dialog {
            margin-inline: 0 !important;
            margin-inline-start: 10px !important;
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: landscape;
        }
    }
</style>
